import React from "react";
import { auth } from "./firebase";
import { MDBBadge } from "mdb-react-ui-kit";
import profileImg from "../assets/profile.png";
import logo from "../assets/logo.png";

const Header = ({ user }) => {
  // console.log(user);
  async function handleLogout() {
    try {
      await auth.signOut();
      window.location.href = "/login";
      console.log("User logged out successfully!");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  }
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-body-tertiary">
      <div class="container">
        <a class="navbar-brand me-2" href="/book-now">
          <img src={logo} alt="" loading="lazy" style={{ marginTop: "-1px" }} />
        </a>

        <button
          data-mdb-collapse-init
          class="navbar-toggler"
          type="button"
          data-mdb-target="#navbarButtonsExample"
          aria-controls="navbarButtonsExample"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fas fa-bars"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarButtonsExample">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>

          <div class="d-flex align-items-center">
            {user?.isAdmin ? (
              <>
                <a
                  href="/backend"
                  data-mdb-ripple-init
                  type="button"
                  class="btn btn-link px-3 me-2"
                >
                  Admin <br />
                </a>
              </>
            ) : (
              ""
            )}
            {user ? (
              <>
                <div className="d-inline-flex position-relative">
                  <MDBBadge className="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
                    <span className="visually-hidden">New alerts</span>
                  </MDBBadge>
                  <img
                    className="rounded-8 shadow-8"
                    src={!user?.photo ? profileImg : user?.photo}
                    alt="Avatar"
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
                <button
                  data-mdb-ripple-init
                  type="button"
                  class="btn btn-link px-3 me-2"
                >
                  Hi {user?.firstName} {user?.lastName} <br />
                </button>
              </>
            ) : (
              ""
            )}

            <button
              data-mdb-ripple-init
              type="button"
              class="btn btn-primary me-3"
              onClick={handleLogout}
            >
              Logout
            </button>
            <a
              data-mdb-ripple-init
              class="btn btn-dark px-3"
              href="https://github.com/mdbootstrap/mdb-ui-kit"
              role="button"
            >
              <i class="fab fa-github"></i>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
