import React, { useEffect } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import SignUp from "./pages/Register";
import Login from "./pages/Login";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./pages/Profile";
import { useState } from "react";
import { auth } from "./components/firebase";
import Backend from "./pages/Backend";

function App() {
  const [user, setUser] = useState();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  });
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={user ? <Navigate to="/book-now" /> : <Login />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Backend />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/book-now" element={<Profile />} />
        </Routes>
        <div className="container">
          <ToastContainer />
        </div>
      </div>
    </Router>
  );
}

export default App;
